// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  disable_console: false,
  appVersion: require('../../package.json').version + '-dev',

  // rootUrl: 'https://restful.gelcargo.com/api',
  // rootUrl: 'https://restful.tiam.tech/api',
  // rootUrl: 'http://127.0.0.1:8000/api',
  // rootUrl: 'http://192.168.29.22:8000/api',
  rootUrl: 'https://dev-be.gelcargo.com/api',
  // rootUrl: 'http://192.168.43.101:8000/api',
  // rootUrl: 'http://127.0.0.1:8000/api',

  rootUrlhost: window.location,

  // url: 'https://restful.gelcargo.com/api',
  // url: 'https://restful.tiam.tech/api',
  // url: 'http://192.168.29.22:8000/api',
  url: 'https://dev-be.gelcargo.com/api',
  // url: 'http://192.168.43.101:8000/api',
  // url: 'http://127.0.0.1:8000/api',
  rootFolder: 'logistics',
  s3url: 'https://tiamtec-logistics-images.s3.ap-south-1.amazonaws.com',
  // ws_url: 'http://127.0.0.1:3000'
  ws_url: 'http://127.0.0.1:6001'
};